import React, {FunctionComponent} from 'react';
import {Translate} from 'react-localize-redux';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {UserImage} from './UserImage';
import {SettingsProps} from '../interfaces/SettingsProps';
import {Location} from 'history';
import TopbarButtons from './TopbarButtons';
import {getLogo, getSidebarFontColor, getSidebarMenuItems} from '../helpers/settingsHelpers';
import {ToggleSidebarActionCreator, ToggleSidebarActionProps} from '../actions/SidebarActionCreator';
import {bindActionCreators} from 'redux';
import {UserProps} from '../interfaces/UserProps';
import {MenuItem, MenuItemType} from '../models/MenuItem';
import {getRealEstateInformationPageUrl} from '../../applications/applicant/api/getRealEstateInformationPageUrl';
import ExternalSystemLink from './ExternalSystemLink';
import {User} from '../models/User';
import {useLocation} from "react-router";
import {isAdmin} from "../helpers/isAdmin";
import Sidebar from "./Sidebar";

type NavigationSidebarStateProps = SettingsProps & UserProps;

const isActiveRouteFunctionCreator = (route: string, location: Location, allowDefault?: boolean) => {
    return () => location.pathname.startsWith(`/${route}`) || (!!allowDefault && location.key === 'default');
};

export const NAVIGATION_SIDEBAR_ID = 'navigation-sidebar';
const NavigationSidebar: FunctionComponent<NavigationSidebarStateProps & ToggleSidebarActionProps> = (props) => {
    const location = useLocation();
    let email = '';
    let name = '';

    if (props.userData.user !== undefined) {
        email = props.userData.user.username;
        name = props.userData.user.displayName;
    }

    const isActiveApplicantsRoute = isActiveRouteFunctionCreator('applicant', location, true);
    const isActiveApplicationsPipelineRoute = isActiveRouteFunctionCreator('applicants/pipeline-view', location, false);
    const isActiveCampaignsRoute = isActiveRouteFunctionCreator('campaign', location, false);
    const isActiveMarketingListsRoute = isActiveRouteFunctionCreator('marketing-list', location, false);
    const isActiveAdministrationRoute = isActiveRouteFunctionCreator('administration', location, false);

    return (
        <Sidebar 
            id={NAVIGATION_SIDEBAR_ID} 
            style={{justifyContent: 'space-between'}}
            title={<TopbarButtons logoSetting={getLogo(props)} />}
        >
            {renderMenu(
                props,
                isActiveApplicantsRoute,
                isActiveApplicationsPipelineRoute,
                isActiveCampaignsRoute,
                isActiveMarketingListsRoute,
                isActiveAdministrationRoute
            )}
            <div className="user-info">
                <div>
                    <UserImage email={email} />
                </div>
                <div style={getSidebarFontColor(props)} className="user-name">
                    {name}
                </div>
            </div>
        </Sidebar>
    );
};

function renderMenu(
    props: NavigationSidebarStateProps & ToggleSidebarActionProps,
    isActiveApplicantsRoute: () => boolean,
    isActiveApplicationsPipelineRoute: () => boolean,
    isActiveCampaignsRoute: () => boolean,
    isActiveMarketingListsRoute: () => boolean,
    isActiveAdministrationRoute: () => boolean
) {
    const settingsMenuItems = getSidebarMenuItems(props);
    const sidebarFontColorStyle = getSidebarFontColor(props);
    return (
        <div className="nav-links-container" onClick={() => props.toggleSidebar(NAVIGATION_SIDEBAR_ID)}>
            {renderStandardMenu(
                props,
                isActiveApplicantsRoute,
                isActiveApplicationsPipelineRoute,
                isActiveCampaignsRoute,
                isActiveMarketingListsRoute,
                isActiveAdministrationRoute
            )}
            {settingsMenuItems !== null && settingsMenuItems.length > 0 ? renderSettingsMenuItems(settingsMenuItems, props.userData.user, sidebarFontColorStyle) : null}
        </div>
    );
}

function renderStandardMenu(
    props: NavigationSidebarStateProps & ToggleSidebarActionProps,
    isActiveApplicantsRoute: () => boolean,
    isActiveApplicationsPipelineRoute: () => boolean,
    isActiveCampaignsRoute: () => boolean,
    isActiveMarketingListsRoute: () => boolean,
    isActiveAdministrationRoute: () => boolean
) {
    const sidebarFontColorStyle = getSidebarFontColor(props);
    return (
        <>
            <NavLink
                to="/applicants?pageNumber=0" 
                style={sidebarFontColorStyle} 
                className={(isActiveApplicantsRoute() && !isActiveApplicationsPipelineRoute()) ? 'active-route' : undefined}
            >
                <Translate id="APPLICANTS" />
            </NavLink>
            {/*TODO: uncomment before prod */}
            {/*<NavLink style={sidebarFontColorStyle} to="/applicants/pipeline-view" className={isActiveApplicationsPipelineRoute() ? 'active-route' : undefined}>*/}
            {/*    <Translate id="APPLICATIONS_PIPELINE_VIEW.TITLE" />*/}
            {/*</NavLink>*/}
            <NavLink style={sidebarFontColorStyle} to="/campaigns" className={isActiveCampaignsRoute() ? 'active-route' : undefined}>
                <Translate id="CAMPAIGNS" />
            </NavLink>
            <NavLink style={sidebarFontColorStyle} to="/marketing-lists" className={isActiveMarketingListsRoute() ? 'active-route' : undefined}>
                <Translate id="MARKETING_LISTS" />
            </NavLink>
            {isAdmin(props) &&
                <NavLink style={sidebarFontColorStyle} to="/administration" className={isActiveAdministrationRoute() ? 'active-route' : undefined}>
                    <Translate id="ADMINISTRATION" />
                </NavLink>
            }
        </>
    );
}

function renderSettingsMenuItems(menuItems: MenuItem[], user: User | undefined, style: {color: string | undefined}) {
    return menuItems
        .filter((mi) => !mi.roles || mi.roles.length === 0 || (user !== undefined && mi.roles.includes(user.role)))
        .map((m) => renderMenuItem(m, style));
}

function renderMenuItem(item: MenuItem, style: {color: string | undefined}) {
    if (item.type === MenuItemType.RealEstateInformationPage) {
        const getUrl = () => getRealEstateInformationPageUrl();
        return <ExternalSystemLink getUrl={getUrl} key={item.label} as="a" style={style}>{item.label}</ExternalSystemLink>;
    } else if (item.url) {
        return <a href={item.url} target="_blank" rel="noreferrer" style={style} key={item.label}>{item.label}</a>;
    }

    return null;
}

const mapStateToProps = (state: any) => ({
    ...state.userActionsReducer,
    ...state.settingsActionsReducer
});

const mapActionCreatorsToProps = (dispatch: any) => bindActionCreators(ToggleSidebarActionCreator, dispatch);

export default connect<NavigationSidebarStateProps, ToggleSidebarActionProps, {}, any>(mapStateToProps, mapActionCreatorsToProps)(NavigationSidebar);
