import {
    JsonHubProtocol,
    HubConnectionState,
    HubConnectionBuilder,
    LogLevel
  } from '@microsoft/signalr';
import { getToken } from '../helpers/AuthProvider';
import { useEffect } from 'react';

const connectionHub = `${process.env.REACT_APP_BASE_API_URL}/signalr/notifications`;
const isDev = process.env.NODE_ENV === 'development';

const options = {
  logMessageContent: isDev,
  logger: process.env.NODE_ENV === 'development' ? LogLevel.Warning : LogLevel.Error,
  accessTokenFactory: () => getToken().then((token) => token.accessToken)
};

const connection = new HubConnectionBuilder()
  .withUrl(connectionHub, options)
  .withAutomaticReconnect()
  .withHubProtocol(new JsonHubProtocol())
  .configureLogging(isDev ? LogLevel.Information : LogLevel.Warning)
  .build();

export function startSignalRConnection() {
  try {
    if (connection.state === HubConnectionState.Connected) {
        return;
    }

    return connection.start();
  } catch (err) {
    setTimeout(startSignalRConnection, 5000);
  }
};

export function registerHandler<T>(methodName: string, callback: (data: T) => void) {
  deregisterHandler(methodName);
  connection.on(methodName, (msg: string) => callback(msg && JSON.parse(msg)));
}

export function deregisterHandler(methodName: string){
    connection.off(methodName);
}

export function useSignalR<T>(methodName: string, callback: (data: T) => void, deps?: React.DependencyList) {  
  useEffect(() => {
    registerHandler(methodName, callback);
    return () => deregisterHandler(methodName); // eslint-disable-next-line
  }, deps || []); 
}