import { Action, Dispatch } from 'redux';
import { ToastMessageType, ToastMessage } from '../models/ToastMessage';
import { useDispatch } from 'react-redux';

// Action Types
export const SHOW_TOAST_MESSAGE = 'SHOW_TOAST_MESSAGE';
export const HIDE_TOAST_MESSAGE = 'HIDE_TOAST_MESSAGE';

// Action Interfaces
interface ShowToastMessageAction extends Action<typeof SHOW_TOAST_MESSAGE> {
    toast: ToastMessage;
}

interface HideToastMessageAction extends Action<typeof HIDE_TOAST_MESSAGE> {
    id: string;
}

export type ToastMessageAction = ShowToastMessageAction | HideToastMessageAction;

// ID Generator
let lastId = 0;
const generateToastId = () => `__TOAST_MESSAGE:${++lastId}__`;

// Action Creators
const createShowToastAction = (
    messageType: ToastMessageType, 
    title: string, 
    content: string | string[], 
    id?: string
): ShowToastMessageAction => ({
    type: SHOW_TOAST_MESSAGE,
    toast: {
        id: id || generateToastId(),
        messageType,
        title,
        content
    }
});

const createHideToastAction = (id: string): HideToastMessageAction => ({
    type: HIDE_TOAST_MESSAGE,
    id
});

// Thunk Actions
export const showToastMessage = (
    messageType: ToastMessageType, 
    title: string, 
    content: string | string[], 
    id?: string
) => (dispatch: Dispatch) => {
    dispatch(createShowToastAction(messageType, title, content, id));
};

export const hideToastMessage = (id: string) => (dispatch: Dispatch) => {
    dispatch(createHideToastAction(id));
};

// Hook
export const useToastMessages = () => {
    const dispatch: Dispatch = useDispatch();
    
    const show = (messageType: ToastMessageType, title: string, content: string | string[], id?: string) => 
        dispatch(createShowToastAction(messageType, title, content, id));
    
    const hide = (id: string) => 
        dispatch(createHideToastAction(id));
    
    return [show, hide] as const;
};

// Props Interface
export interface ShowToastMessageProps {
    showToastMessage: typeof showToastMessage;
}
