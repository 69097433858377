import { AxiosClientFactory } from '../helpers/AxiosClientFactory';
import { CommandResult } from '..//helpers/CommandResult';
import { ActiveSession } from '../models/ActiveSession';

const path = '/api/me/activeSession';

export const startActiveSession = async (end: Date): Promise<CommandResult> => {
    const session: ActiveSession = { start: new Date(), end };
    const result = await AxiosClientFactory
        .getInstance()
        .post(path, session);

    return new CommandResult(result);
};

export const stopActiveSession = async (): Promise<CommandResult> => {
    const result = await AxiosClientFactory
        .getInstance()
        .delete(path);

    return new CommandResult(result);
};

export const getActiveSession = async (): Promise<ActiveSession | null> => {
    const result = await AxiosClientFactory
        .getInstance()
        .get<ActiveSession>(path);

    const session = result.data;

    if (!session)
    {
        return null;
    }

    session.start = new Date(session.start);
    session.end = new Date(session.end);
    return session;
}