import { SessionState, SessionAction } from './types';
import { calculateRemainingTime } from './dateLogic';

export const initialState: SessionState = {
    sessionEnd: null,
    remainingTime: null,
    isProcessing: false,
    showPopup: false
};

export function sessionReducer(state: SessionState, action: SessionAction): SessionState {
    switch (action.type) {
        case 'SHOW_POPUP':
            return { ...state, showPopup: true };
        case 'HIDE_POPUP':
            return { ...state, showPopup: false };
        case 'START_PROCESSING_REQUEST':
            return { ...state, isProcessing: true };
        case 'STOP_PROCESSING_REQUEST':
            return { ...state, isProcessing: false };
        case 'START_SESSION':
            return {
                ...state,
                sessionEnd: action.sessionEnd,
                remainingTime: calculateRemainingTime(action.sessionEnd),
                showPopup: false,
                isProcessing: false,
            };
        case 'UPDATE_REMAINING_TIME':
            return {
                ...state,
                remainingTime: action.remainingTime,
                sessionEnd: action.remainingTime === null ? null : state.sessionEnd
            };
        case 'STOP_SESSION':
            return {
                ...state,
                sessionEnd: null,
                remainingTime: null,
                isProcessing: false,
            };
        default:
            return state;
    }
}