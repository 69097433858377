import moment from 'moment';

export const calculateEndOfSession = (sessionLengthInHours: number): Date => {
    const endTime = moment()
        .add(sessionLengthInHours, 'hours')
        .minutes(Math.floor(moment().minutes() / 5) * 5)
        .seconds(0)
        .milliseconds(0);
    
    return endTime.toDate();
};

export const calculateRemainingTime = (sessionEnd: Date): string | null => {
    const duration = sessionEnd.getTime() - Date.now();
    
    if (duration <= 0) {
        return null;
    }

    return moment.utc(duration).format("HH:mm");
};
