import React, { FunctionComponent, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import DateTimeInputField, { DateTimeInputFieldKind } from "../input-fields/DateTimeInputField";
import Translate_i18next from "../Translate_i18next";

interface ActiveSessionManagerPopupProps {
    show: boolean;
    sessionEnd: Date;
    startSession: (date: Date) => void;
    cancel: () => void;
}

export const ActiveSessionManagerPopup: FunctionComponent<ActiveSessionManagerPopupProps> = (props) => {
    const [sessionEnd, setSessionEnd] = useState<Date>(props.sessionEnd);
    const onDateChanged = (date: Date | null) => date && setSessionEnd(date);

    return (
        <Modal show={props.show} centered={true} backdrop={true} dialogClassName="active-session-manager-popup">
        <form>
            <Modal.Header>
                <Modal.Title>
                    <Translate_i18next id="ACTIVE_SESSION.START_SESSION" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Translate_i18next id="ACTIVE_SESSION.PLAN_TO_FINISH_WORK" />
                    <DateTimeInputField
                        className="session-time-picker"
                        name="sessionEnd"
                        value={sessionEnd}
                        editMode={true}
                        onValueChanged={onDateChanged}
                        kind={DateTimeInputFieldKind.Time}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" className="submit-button" onClick={() => props.startSession(sessionEnd)}>
                    <Translate_i18next id="START" />
                </Button>
                <Button variant="secondary" onClick={props.cancel}>
                    <Translate_i18next id="CANCEL" />
                </Button>
            </Modal.Footer>
        </form>
    </Modal>
    );
};
