import React, {FunctionComponent} from 'react';
import {connect} from 'react-redux';
import TopbarButtons from './TopbarButtons';
import {SettingsProps} from '../interfaces/SettingsProps';
import {getLogo, getSidebarBackgroundColorStyle, getSidebarFontColor} from '../helpers/settingsHelpers';
import UserInfo from "./UserInfo";
import { ActiveSessionManager } from './active-session-manager/ActiveSessionManager';
import { UserProps } from '../interfaces/UserProps';

type TopbarProps = SettingsProps & UserProps;

const Topbar: FunctionComponent<TopbarProps> = (props) => {

    const sidebarBackgroundColorStyle = {
        ...getSidebarBackgroundColorStyle(props),
        ...getSidebarFontColor(props)
    };

    return (
        <div className="topbar" style={sidebarBackgroundColorStyle}>
            <TopbarButtons logoSetting={getLogo(props)} />
            {renderActiveSessionManager(props)}
            <UserInfo/>
        </div>
    );
};

const renderActiveSessionManager  = (props: UserProps) =>
    props.userData.user && props.userData.user.defaultWorkingTime !== null
    ? <ActiveSessionManager defaultWorkingTime={props.userData.user.defaultWorkingTime} />
    : null;

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer,
    ...state.userActionsReducer
});

export default connect<TopbarProps, {}, {}, any>(mapStateToProps)(Topbar);
